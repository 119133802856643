import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiAxios from '../api/apiAxios';
import styles from './VerifyEmail.module.css';
import img from '../../src/assets/Logotipo escola municipal minimalista azul e laranja.png';

const VerifyEmail = () => {
    const { token } = useParams();
    const navigate = useNavigate(); 
    const [message, setMessage] = useState('');

    const handleVerifyEmail = async () => {
        setMessage('Verificando...');
        try {
            const response = await apiAxios.get(`/api/req/verify-email/${token}`);
            setMessage(response.data.message);
            if (response.data.message === 'Email verificado com sucesso!') {
                navigate('/success'); // Navega para /success se o email for verificado
            }
        } catch (error) {
            setMessage('Erro ao verificar email. O token pode estar expirado ou inválido.');
        }
    };

    return (
        <div className={styles.all}>
            <div className={styles.logo}>
                <img src={img} alt="Logo" />
            </div>
            <div className={styles.content}>
                <p  style={{width:"90%",margintop:"20px"}}>Olá,</p>
                <p style={{width:"90%",marginBottom:"30px"}}>Bem-vindo à plataforma Educação Alternativa 360! Estamos animados por você ter se juntado a nós.</p>
                <p style={{width:"90%",marginBottom:"40px"}}>Preparado para começar? Primeiro, confirme seu endereço de e-mail.</p>
                <button onClick={handleVerifyEmail}>Confirmar Email</button>
                {message && <p style={{width:"90%", marginTop: '10px'}}>{message}</p>}
                <hr />
                <p>Você pode usar este e-mail para fazer login na plataforma Educação Alternativa 360. Como você é um novo membro da nossa plataforma, gostaríamos de compartilhar algumas informações.</p>
                <ul>
                    <li>Confira nossa <a href='/politica-de-privacidade'>Política de Privacidade</a>.</li>
                    <li>Confira nossa <a href='/politica-de-servico'>Política de Serviço</a>.</li>
                </ul>
            </div>
        </div>
    );
}

export default VerifyEmail;
