import React, { useEffect, useState } from 'react';
import styles from './Home.module.css';
import Header from '../components/Header';
import img from '../assets/R.jpg';
import apiAxios from '../api/apiAxios';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdFileUpload } from "react-icons/md";
import userIcon from "../assets/user.png"
import { useNavigate } from 'react-router-dom';
import { series, turno } from '../DataSeries';

const HomeEscola = () => {
    const navigate = useNavigate();
    const [tenantId, setTenantId] = useState('');
    const [tenantIds, setTenants] = useState([]);
    const [formData, setFormData] = useState({
        tenantId: '',
        isAdminMaster: false,
        isAdmin: false,
        isProfessor: false,
        isSecretaria: false,
        isCoordenacao: false,
        isNutricionista: false,
        firstName: '',
        lastName: '',
        email: '',
        CPF: '',
        img: '',
        phoneNumber: '',
        password: '',
        professorInfo: [{ serie: '', turno: '' }],
    });

    const [imageUrl, setImageUrl] = useState(null);

    const selectSerie = [
        { id: 0, serie: 'Selecione a série', value: '' },
        ...series.map((item, index) => ({
            id: index + 1,
            serie: item,
            value: item
        }))
    ];

    const selectTurn = [
        { id: 0, turn: 'Selecione o turno', value: '' },
        ...turno.map((item, index) => ({
            id: index + 1,
            turn: item,
            value: item
        }))
    ];

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const { data } = await apiAxios.get('/api/user/tenants');
                setTenants(data.tenants);
            } catch (error) {
                console.error('Error fetching tenants:', error);
            }
        };
        fetchTenants();
    }, []);

    const formatCPF = (cpf) => {
        return cpf
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
            .substring(0, 14);
    };

    const formatPhoneNumber = (phone) => {
        return phone
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, '+$1 ($2')
            .replace(/(\d{2})(\d)/, '$1) $2')
            .replace(/(\d{4,5})(\d{4})$/, '$1-$2')
            .substring(0, 19);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.requestType === 'professor' && formData.professorInfo.length === 0) return;

        try {
            const storage = getStorage(app);
            let imageUrl = '';
            if (formData.img) {
                const storageRef = ref(storage, `userImages/${formData.email}-${Date.now()}`);
                await uploadBytes(storageRef, formData.img);
                imageUrl = await getDownloadURL(storageRef);
            }

            const cleanCPF = formData.CPF.replace(/\D/g, '');
            const cleanPhoneNumber = formData.phoneNumber.replace(/\D/g, '');

            await apiAxios.post('api/req/requests', {
                ...formData,
                CPF: cleanCPF,
                phoneNumber: cleanPhoneNumber,
                tenantId: tenantId,
                img: imageUrl
            });

            setFormData({
                tenantId: '',
                isAdminMaster: false,
                isAdmin: false,
                isProfessor: false,
                isSecretaria: false,
                isCoordenacao: false,
                isNutricionista: false,
                firstName: '',
                lastName: '',
                email: '',
                CPF: '',
                img: '',
                phoneNumber: '',
                password: '',
                professorInfo: [{ serie: '', turno: "" }]
            });
            navigate('/verify-email');
        } catch (error) {
            console.error('Erro ao enviar a solicitação:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'CPF') {
            setFormData(prevState => ({ ...prevState, CPF: formatCPF(value) }));
        } else if (name === 'phoneNumber') {
            setFormData(prevState => ({ ...prevState, phoneNumber: formatPhoneNumber(value) }));
        } else if (name === 'requestType') {
            setFormData({
                ...formData,
                requestType: value,
                isProfessor: value === 'professor',
                isSecretaria: value === 'secretaria',
                isCoordenacao: value === 'coordenacao',
                isNutricionista: value === 'nutricionista',
            });
        } else {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setFormData(prevState => ({ ...prevState, img: file }));

        if (file && typeof URL !== 'undefined') {
            const objectUrl = URL.createObjectURL(file);
            setImageUrl(objectUrl);
        } else {
            setImageUrl(null);
        }
    };

    const addProfessorInfo = () => {
        setFormData((prevState) => ({
            ...prevState,
            professorInfo: [...prevState.professorInfo, { serie: '', turno: '' }]
        }));
    };

    const removeProfessorInfo = (index) => {
        if (formData.professorInfo.length === 1) return;
        setFormData((prevState) => ({
            ...prevState,
            professorInfo: prevState.professorInfo.filter((_, i) => i !== index)
        }));
    };

    const handleProfessorInfoChange = (e, index, field) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            professorInfo: prevState.professorInfo.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            )
        }));
    };

    return (
        <div className={styles.all}>
            <Header />
            <div className={styles.contentForm}>
                <div className={styles.imgBanner}>
                    <img src={img} alt='img'></img>
                    <div className={styles.Textimgbanner}>
                        <h1>Solicitação de Cadastro</h1>
                        <text>Os dados fornecidos neste formulário devem ser da pessoa que está solicitando o cadastro. Com exceção da foto, todos os campos são obrigatórios.</text>
                    </div>
                    <div className={styles.form}>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <p>Sua Escola:</p>
                                <label>
                                    <select value={tenantId} onChange={(e) => setTenantId(e.target.value)} required >
                                        <option value="">Selecione a sua escola</option>
                                        {tenantIds.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.SchoolName}</option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                            <div>
                                <p>Tipo de Solicitante:</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="requestType"
                                        value="professor"
                                        checked={formData.requestType === 'professor'}
                                        onChange={handleChange}
                                    /> Professor
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="requestType"
                                        value="secretaria"
                                        checked={formData.requestType === 'secretaria'}
                                        onChange={handleChange}
                                    /> Secretaria
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="requestType"
                                        value="coordenacao"
                                        checked={formData.requestType === 'coordenacao'}
                                        onChange={handleChange}
                                    /> Coordenação
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="requestType"
                                        value="nutricionista"
                                        checked={formData.requestType === 'nutricionista'}
                                        onChange={handleChange}
                                    /> Nutricionista
                                </label>
                                <br />
                                {formData.requestType === 'professor' && (
                                    <>
                                        {formData.professorInfo.map((item, index) => (
                                            <div style={{ width: "100%" }} key={index}>
                                                <select
                                                    value={item.serie}
                                                    onChange={(e) => handleProfessorInfoChange(e, index, 'serie')}
                                                >
                                                    {selectSerie.map((serie) => (
                                                        <option key={serie.id} value={serie.value}>{serie.serie}</option>
                                                    ))}
                                                </select>
                                                <select
                                                    value={item.turno}
                                                    onChange={(e) => handleProfessorInfoChange(e, index, 'turno')}
                                                >
                                                    {selectTurn.map((turn) => (
                                                        <option key={turn.id} value={turn.value}>{turn.turn}</option>
                                                    ))}
                                                </select>
                                                {formData.professorInfo.length !== 1 && (
                                                    <button style={{ background: "#c6c3bd", display: "flex", justifyContent: "center", alignItems: "center", gap: "3px" }} onClick={() => removeProfessorInfo(index)}><IoIosRemoveCircleOutline /> Remover</button>
                                                )}
                                            </div>
                                        ))}
                                        <button style={{ background: "#414141", display: "flex", justifyContent: "center", alignItems: "center", gap: "3px" }} onClick={addProfessorInfo}><IoIosAddCircleOutline /> Adicionar Série e Turno</button>
                                    </>
                                )}
                            </div>
                            <div>
                                <p>Anexar imagem:</p>
                                {imageUrl ? <img src={imageUrl} className={styles.RenderFileInput} alt="Imagem selecionada" /> : <img src={userIcon} className={styles.RenderFileInput} alt="Imagem selecionada" />}
                                <label htmlFor="profileImage" className={styles.customFileInput}>
                                    <MdFileUpload /> Escolher arquivo
                                </label>
                                <input
                                    type="file"
                                    id="profileImage"
                                    name="profileImage"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                                <p>Dados de contato:</p>
                                <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Nome" required />
                                <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Sobrenome" required />
                                <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="E-mail" required />
                                <input type="text" name="CPF" minLength={11} maxLength={14} value={formData.CPF} onChange={handleChange} placeholder="Seu CPF" required />
                                <input type="text" name="phoneNumber" maxLength={19} value={formData.phoneNumber} onChange={handleChange} placeholder="Telefone com DDD e código do país" required />
                                <p>Senha:</p>
                                <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Senha" required />
                                <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="Confirmar Senha" required />
                                {formData.password !== formData.confirmPassword && formData.confirmPassword && <p style={{ color: 'red' }}>As senhas não coincidem</p>}
                                <button type="submit">Enviar Solicitação</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeEscola;
