import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import styles from './SuccessPage.module.css'
import img from '../assets/R.jpg';
import { HiCheckCircle } from "react-icons/hi";
import LoadingPage from './LoadingPage';

const SuccessPage = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula um delay para exibir o loading, substitua pelo seu processo real
        setTimeout(() => {
            setLoading(false);
        }, 3000); // Ajuste o tempo conforme necessário
    }, []);

    if (loading) {
        return <LoadingPage />;
    }
    return (
        <div className={styles.all}>
            <Header />
            <div className={styles.contentForm}>
                <div className={styles.imgBanner}>
                    <img src={img} alt='img'></img>
                    <div className={styles.Textimgbanner}>
                        <h1>Cadastro Solicitado Com Sucesso!</h1>
                        <text>Não compartilhamos suas informações pessoais com terceiros sem o seu consentimento expresso. Você tem o direito de acessar, corrigir e excluir seus dados pessoais a qualquer momento.</text>
                    </div>
                    <div className={styles.form}>
                        <div className={styles.successMensage}>
                            <HiCheckCircle />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessPage