import axios from 'axios';
import { useSelector } from 'react-redux';

const apiAxios = axios.create({
    baseURL: "https://portal-alternativo-backend-2-0.vercel.app/",
    //baseURL: "http://localhost:5000"
});

const ApiAxiosInterceptor = () => {
    const token = useSelector((state) => state.user?.userInfo?.data?.token);
    apiAxios.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return null; // Não retorna nada, pois não é um componente visual
};

export default apiAxios;
export { ApiAxiosInterceptor };