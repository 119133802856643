import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingPage = () => {
  return (
    <div style={styles.container}>
      <ClipLoader color="#ec3237" loading={true} size={100} />
      <p style={styles.text}>Carregando<span style={styles.dotAnimation}>...</span></p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
    color: '#333',
    fontFamily: 'Arial, sans-serif',
  },
  text: {
    marginTop: '20px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#ec3237',
  },
  dotAnimation: {
    animation: 'dotBlink 1s infinite', // Animation name, duration, and iteration
    display: 'inline-block', // Ensuring the span behaves like an inline-block element
  },
  '@keyframes dotBlink': { // Keyframes for the animation
    '0%, 100%': { transform: 'translateY(0)' }, // Start and end, no translation
    '50%': { transform: 'translateY(-10px)' }, // Middle, move up by 10px
  },
};

export default LoadingPage;
