import React from 'react'
import styles from './Header.module.css';
import img from '../../src/assets/Logotipo escola municipal minimalista azul e laranja.png';

const Header = () => {
  return (
    <header className={styles.header}>
        <img src={img} alt='logo'></img>
        <a href="http://escola.educacaoalternativa360.com.br">Entrar</a>
    </header>
  )
}

export default Header