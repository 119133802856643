import React, { useEffect, useState } from 'react';
import styles from './Home.module.css';
import Header from '../components/Header';
import img from '../assets/R.jpg';
import apiAxios from '../api/apiAxios';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    const [tenantIds, setTenants] = useState([]);
    const [formData, setFormData] = useState({
        tenantId: '',
        isResponsavel: true,
        firstName: '',
        lastName: '',
        email: '',
        CPF: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        alunos: [],
    });
    const [errors, setErrors] = useState({
        CPF: '',
        phoneNumber: '',
    });


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const { data } = await apiAxios.get('/api/user/tenants');
                setTenants(data.tenants || []);
            } catch (error) {
                console.error('Erro ao buscar escolas:', error);
            }
        };
        fetchTenants();
    }, []);

    const validateCPF = (cpf) => {
        const cleanCPF = cpf.replace(/\D/g, '');
        return cleanCPF.length === 11;
    };

    const validatePhoneNumber = (phone) => {
        const cleanPhone = phone.replace(/\D/g, '');
        return cleanPhone.length === 13;
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (name === 'CPF' && !validateCPF(value)) {
            alert('CPF deve ter exatamente 11 dígitos.');
        } else if (name === 'phoneNumber' && !validatePhoneNumber(value)) {
            alert('O telefone deve ter exatamente 13 dígitos.');
        }
    };

    const handleAddAlunoByCPF = async (CPF) => {
        if (!CPF) {
            alert('Por favor, insira um CPF válido.');
            return;
        }
    
        if (!validateCPF(CPF)) {
            alert('CPF do aluno deve ter exatamente 11 dígitos.');
            return;
        }
    
        // Verifica se o CPF já foi adicionado
        if (formData.alunos.some((aluno) => aluno.CPF === CPF)) {
            alert('Este CPF já foi adicionado.');
            return;
        }
    
        try {
            setLoading(true);
            const cleanCPF = CPF.replace(/\D/g, ''); // Remove caracteres não numéricos
            console.log('Buscando aluno com CPF:', cleanCPF);
    
            const { data: user } = await apiAxios.get(`/api/user/users/search-by-cpf/${cleanCPF}`);
            console.log('Dados do aluno recebidos:', user);
    
            if (user && typeof user === 'object') {
                setFormData((prevState) => ({
                    ...prevState,
                    alunos: [
                        ...prevState.alunos,
                        {
                            nome: `${user.user.firstName || ''} ${user.user.lastName || ''}`.trim(),
                            CPF: user.user.CPF,
                            serie: user.user.alunoInfo?.serie || '',
                            turno: user.user.alunoInfo?.turno || '',
                        },
                    ],
                }));
            } else {
                alert('Aluno não encontrado.');
            }
        } catch (error) {
            console.error('Erro ao buscar aluno pelo CPF:', error);
            alert('Erro ao buscar aluno.');
        } finally {
            setLoading(false);
        }
    };
    


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            alert('As senhas não coincidem.');
            return;
        }

        if (!formData.tenantId) {
            alert('Por favor, selecione uma escola.');
            return;
        }

        if (!validateCPF(formData.CPF)) {
            alert('CPF do responsável deve ter exatamente 11 dígitos.');
            return;
        }

        if (!validatePhoneNumber(formData.phoneNumber)) {
            alert('O telefone deve ter exatamente 13 dígitos.');
            return;
        }

        try {
            const cleanCPF = formData.CPF.replace(/\D/g, '');

            const responsavelInfo = formData.alunos.map((aluno) => ({
                nomeAluno: aluno.nome,
                CPF: aluno.CPF,
                serie: aluno.serie,
                turno: aluno.turno,
            }));

            const payload = {
                ...formData,
                CPF: cleanCPF,
                alunos: formData.alunos.map((aluno) => ({
                    ...aluno,
                    CPF: aluno.CPF.replace(/\D/g, ''),
                })),
                responsavelInfo,
            };

            console.log('Payload enviado:', payload);

            await apiAxios.post('/api/req/requests', payload);
            alert('Solicitação enviada com sucesso!');
            navigate('/verify-email');
        } catch (error) {
            console.error('Erro ao enviar a solicitação:', error.response?.data || error.message);
            alert('Erro ao enviar a solicitação.');
        }
    };

    const handleTenantChange = (e) => {
        const selectedTenantId = e.target.value;
        setFormData((prevState) => ({ ...prevState, tenantId: selectedTenantId }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Permitir apenas números e limitar a entrada
        if (name === 'CPF' && (!/^\d*$/.test(value) || value.length > 11)) {
            return;
        }
        if (name === 'phoneNumber' && (!/^\d*$/.test(value) || value.length > 13)) {
            return;
        }

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'CPF') {
            setErrors((prevState) => ({
                ...prevState,
                CPF: validateCPF(value) ? '' : 'Deve ter 11 dígitos.',
            }));
        }

        if (name === 'phoneNumber') {
            setErrors((prevState) => ({
                ...prevState,
                phoneNumber: validatePhoneNumber(value) ? '' : 'Deve ter 13 dígitos.',
            }));
        }
    };


    return (
        <div className={styles.all}>
            <Header />
            <div className={styles.contentForm}>
                <div className={styles.imgBanner}>
                    <img src={img} alt="img" />
                    <div className={styles.Textimgbanner}>
                        <h1>Solicitação de Cadastro</h1>
                        <p>Os dados fornecidos neste formulário devem ser da pessoa que está solicitando o cadastro.</p>
                    </div>
                </div>
                <div className={styles.form}>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <p>Sua Escola:</p>
                            <select value={formData.tenantId} onChange={handleTenantChange} required>
                                <option value="">Selecione a sua escola</option>
                                {(tenantIds || []).map((tenant) => (
                                    <option key={tenant._id} value={tenant._id}>
                                        {tenant.SchoolName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className={styles.searchWrapper}>
                            <h2 className={styles.title}>Buscar Aluno</h2>
                            <div className={styles.searchBarContainer}>
                                <input
                                    type="text"
                                    className={styles.searchBarInput}
                                    placeholder="Digite o CPF do aluno (11 dígitos)"
                                    aria-label="Digite o CPF do aluno (11 dígitos)"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 11) {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                alunoCPF: value,
                                            }));
                                        }
                                    }}
                                    value={formData.alunoCPF || ''}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleAddAlunoByCPF(formData.alunoCPF);
                                        }
                                    }}
                                />
                                <p style={{color:"#ffc423"}}>*Aperte na lupa para adicionar.</p>
                                <button
                                    type="button"
                                    className={styles.searchButton}
                                    onClick={() => handleAddAlunoByCPF(formData.alunoCPF)}
                                    aria-label="Buscar aluno"
                                    style={{background:"transparent"}}
                                >
                                    <svg
                                        className={styles.searchIcon}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="16" y1="16" x2="22" y2="22"></line>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div>
                            {formData.alunos.length <= 0 ? "" : <p>Alunos Adicionados:</p>}
                            {formData.alunos.map((aluno, index) => (
                                <div key={index} className={styles.alunoInfo}>
                                    <h3>Aluno {index + 1}</h3>
                                    <p><strong>Nome:</strong> {aluno.nome}</p>
                                    <p><strong>CPF:</strong> {aluno.CPF}</p>
                                    <p><strong>Série:</strong> {aluno.serie}</p>
                                    <p><strong>Turno:</strong> {aluno.turno}</p>
                                </div>
                            ))}
                        </div>

                        <div>
                            <p>Dados do Responsável:</p>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                placeholder="Nome"
                                required
                            />
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder="Sobrenome"
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="E-mail"
                                required
                            />
                            <div>
                                <p>CPF do Responsável:</p>
                                <input
                                    type="number"
                                    name="CPF"
                                    value={formData.CPF}
                                    onChange={handleChange}
                                    placeholder="CPF (11 dígitos)"
                                    maxLength={11}
                                    required
                                />
                                {errors.CPF && <p style={{ color: 'red' }}>{errors.CPF}</p>}
                            </div>
                            <div>
                                <p>Telefone:</p>
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    placeholder="Telefone (13 dígitos)"
                                    maxLength={13}
                                    required
                                />
                                {errors.phoneNumber && <p style={{ color: 'red' }}>{errors.phoneNumber}</p>}
                            </div>
                            <p>Senha:</p>
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Senha"
                                required
                            />
                            <input
                                type="password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                placeholder="Confirmar Senha"
                                required
                            />
                            {formData.password !== formData.confirmPassword && formData.confirmPassword && (
                                <p style={{ color: 'red' }}>As senhas não coincidem.</p>
                            )}
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Carregando...' : 'Enviar Solicitação'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Home;
