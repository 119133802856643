export const series = [
    // Educação Infantil
    'Infantil I',
    'Infantil II',
    'Infantil III',
    'Infantil IV',
    'Infantil V',

    // Ensino Fundamental I
    '1º ano',
    '2º ano',
    '3º ano',
    '4º ano',
    '5º ano',

    // Ensino Fundamental II
    '6º ano',
    '7º ano',
    '8º ano',
    '9º ano',

    // Ensino Médio
    '1º ano do Ensino Médio',
    '2º ano do Ensino Médio',
    '3º ano do Ensino Médio'
];

export const turno = [
    'Manhã',
    'Tarde',
    'Noite'
];
