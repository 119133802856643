import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import SuccessPage from './pages/SuccessPage';
import VerifyEmail from './pages/VerifyEmail';
import EmailAsk from './pages/EmailAsk';
import HomeEscola from './pages/HomeEscola';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/escola' element={<HomeEscola/>} />
        <Route path='/success' element={<SuccessPage/>}/>
        <Route path='/verify-email' element={<EmailAsk/>}/>
        <Route path='/verify-email/:token' element={<VerifyEmail/>}/>
      </Routes>
    </Router>
  );
}

export default App;
